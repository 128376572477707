<template>
  <div id="share">
    <vs-progress class="w-full" v-if="!loaded" indeterminate color="primary"></vs-progress>
    <div v-else>
      <div class="vx-row w-full">
        <div class="vx-col w-2/5">
          <vs-input class="mt-2" :label="$t('shareHash')" v-model="shareEdited.hash" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('creationDate')" v-model="shareEdited.sql_timestamp" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('Portal')" v-model="shareEdited.portal_nicename" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('Creator')" :value="`${shareEdited.first_name} ${shareEdited.last_name}`" :disabled="true"/>
        </div>
        <div class="vx-col w-2/5">
          <vs-input type="date" class="mt-2" :label="$t('expireDate')" v-model="shareEdited.expire_date"/>
          <vs-input disabled class="mt-2" :label="$t('password')" v-model="shareEdited.password"/>
          <vs-input type="number" class="mt-2" :label="$t('useLimit')" v-model="shareEdited.use_limit" v-validate="'decimal:0'" :color="validateForm ? 'success' : 'danger'"/>
          <vs-input class="mt-2" :label="$t('users')" v-model="shareEdited.users" :disabled="true"/>
        </div>
        <div class="vx-col w-1/5">
          <vs-button class="m-6 w-full" @click="dispatchUpdates" :disabled="!diffsToApiQuery">{{this.$t('Save')}}</vs-button>
          <vs-button color="danger" class="m-6 w-full" @click="deleteShare">{{this.$t('Delete')}}</vs-button>
          <!-- <pre>
            {{diffsToApiQuery}}
          </pre> -->
        </div>
      </div>
      <div class="mt-6">
        <h3>{{$t('Capabilities')}}</h3>
        <vx-card class="m-6" v-for="(caps, index) in share.caps" :key="index">
          <h4 class="mb-2">{{decodeURIComponent(caps.project_nicename)}}</h4>
          <div class="flex">
            <div class="w-1/3">
              <div v-for="basicCap in projectCapsBasicCheckboxes" :key="caps.project_slug+'_'+basicCap" class="mt-1 flex">
                <vs-switch disabled class="ml-5" v-model="caps[basicCap]" />
                <label class="ml-5">{{$t(basicCap)}}</label>
              </div>
            </div>
            <div class="w-1/3">
              <div v-for="warningCap in projectCapsWarningCheckboxes" :key="caps.project_slug+'_'+warningCap" class="mt-1 flex">
                <vs-switch disabled class="ml-5" v-model="caps[warningCap]" color="warning" icon-pack="feather" vs-icon="icon-alert-circle"/>
                <label class="ml-5">{{$t(warningCap)}}</label>
              </div>
            </div>
            <div class="w-1/3">
              <div v-for="adminCap in projectCapsAdminCheckboxes" :key="caps.project_slug+'_'+adminCap" class="mt-1 flex">
                <vs-switch disabled class="ml-5" v-model="caps[adminCap]" color="danger" icon-pack="feather" vs-icon="icon-alert-triangle"/>
                <label class="ml-5">{{$t(adminCap)}}</label>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@/axios.js'
import Datepicker from 'vuejs-datepicker'
import { getDiff } from 'recursive-diff'
// import CryptoJS from 'crypto-js'
// import md5 from 'crypto-js/md5'

export default {
  components: {
    Datepicker
  },
  data () {
    return {
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      apiRoute: '/admin/share/getShare',
      loaded: false,
      share_hash: null, 
      share: null,
      shareEdited: null,
      displayValidationBtn: false,
      projectCapsBasicCheckboxes: [
        'can_project_model_see',
        'can_project_tags_see',
        'can_project_tools_use',
        'can_project_users_see'
      ],
      projectCapsWarningCheckboxes: [
        'can_project_tags_add',
        'can_project_shares_send'
      ],
      projectCapsAdminCheckboxes: [
        'can_project_customize',
        'can_project_tags_manage',
        'can_project_users_manage',
        'can_project_layers_manage'
      ],
      projectCapsDates: [
        'can_project_access_start_date',
        'can_project_access_end_date'
      ]
    }
  },
  methods: {
    validateForm () {
      return !this.errors.any() && this.validDiffs
    },
    getEntityData () {
      const params = `share_hash=${this.share_hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}${this.apiRoute}/?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          const data = response.data
          data.forEach(share => {
            if (share.caps.includes('{')) {
              share.caps = JSON.parse(share.caps)
            } else {
              share.caps = JSON.parse(decodeURIComponent(share.caps))
            }
          })
          this.share = JSON.parse(JSON.stringify(data[0]))
          this.shareEdited = JSON.parse(JSON.stringify(data[0]))
          this.loaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    getValues (serie) {
      return Object.entries(serie)
        .map(item => parseFloat(item[1].result))
    },
    deleteShare () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: this.$t('deleteShareText'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: () => {
          const params = new FormData()
          params.set('share_hash', JSON.stringify(this.share_hash))
          params.set('context', JSON.stringify({services_user_id: this.$store.state.AppActiveUser.user_id, services_user_hash: this.$store.state.AppActiveUser.user_hash}))
          const rimnat_api_call_url = `${this.api_server_baseurl}/admin/share/deleteShare`
          axios.post(rimnat_api_call_url, params)
            .then((response) => { 
              if (response.data[0] === 'ok' || response.data == 'ok') {
                this.$vs.notify({
                  color:'success',
                  title:this.$t('success'),
                  text:this.$t('success')
                })
              } else {
                console.log(response)
                this.$vs.notify({
                  color:'danger',
                  title:this.$t('error'),
                  text:response.data
                })
              }
              this.$router.push('/admin/shares/')
            })
            .catch((error)   => { 
              console.log(error)
              this.$vs.notify({
                color:'danger',
                title:this.$t('error'),
                text:error
              })
              this.$router.push('/admin/shares/')
            })
        }
      })
    },
    dispatchUpdates () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: this.$t('dispatchShareUpdatesText'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: () => {
          const params = new FormData()
          for (const [key, value] of Object.entries(this.diffsToApiQuery)) {
            params.set(key, JSON.stringify(value))
          }
          const rimnat_api_call_url = `${this.api_server_baseurl}/admin/share/updateShareSettings`
          axios.post(rimnat_api_call_url, params)
            .then((response) => { 
              if (response.data[0] === 'ok' || response.data == 'ok') {
                this.$vs.notify({
                  color:'success',
                  title:this.$t('success'),
                  text:this.$t('success')
                })
              } else {
                console.log(response)
                this.$vs.notify({
                  color:'danger',
                  title:this.$t('error'),
                  text:response.data
                })
              }
              this.refreshAll()
            })
            .catch((error)   => { 
              console.log(error)
              this.$vs.notify({
                color:'danger',
                title:this.$t('error'),
                text:error
              })
              this.refreshAll()
            })
        }
      })
    }, 
    refreshAll () {
      this.loaded = false
      this.getEntityData()
    }
  },
 
  computed: {
    diffsToApiQuery () {
      if (this.diffs.length == 0) return 
      return { 
        context: {
          services_user_id: this.$store.state.AppActiveUser.user_id,
          services_user_hash: this.$store.state.AppActiveUser.user_hash
        }, 
        diffs: this.diffsToApi
      }
    },
    diffsToApi () {
      if (this.diffs.length == 0) return 
      const res = {
        share_hash : this.share_hash
      }
      const props = []
      this.diffs.forEach(e => {
        if (e.op == 'update' && e.val != e.oldVal) {
          const prop = e.path[0]
          const val = e.val
          props.push({prop, val})
        }
      })
      res.updates = props
      return res
    },
    diffs () {
      return getDiff(this.share, this.shareEdited, true)
    }
  },

  beforeMount () {
    this.share_hash = this.$route.params.share_hash
  },

  mounted () {
    document.body.classList.add('theme-dark')
    this.getEntityData()
  },

  watch: {
  }
}

</script>
<style lang="scss">

.theme-dark input {
    background: none !important;
}
label {
  font-size: 0.85rem
}
</style>
